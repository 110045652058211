import LoadingButton from "@mui/lab/LoadingButton";
import Box from '@mui/material/Box';
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ExportDataGridToExcel } from "../../../../../export/ExportDataGridToExcel";
import { SecretService } from "../../../../service/SecretService";
const baseColumnOptions = {
    sortable: false,
    pinnable: false,
    hideable: false,
};

const nationalityColumns = [
    {
        field: "id",
        header: "Id",
        hideable: true,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "siteName",
        headerName: "Site",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        cellClassName: "tableLeftColumn",
    },
    {
        field: "nationality0",
        headerName: "1",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationalityPercentage0",
        headerName: "Quantité %",
        ...baseColumnOptions,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? Number(data?.value)?.toFixed(2) + " %" : "",
    },
    {
        field: "nationalityNb0",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationality1",
        headerName: "2",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationalityPercentage1",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : ""
    },
    {
        field: "nationalityNb1",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationality2",
        headerName: "3",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationalityPercentage2",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "nationalityNb2",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationality3",
        headerName: "4",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationalityPercentage3",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "nationalityNb3",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationality4",
        headerName: "5",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationalityPercentage4",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "nationalityNb4",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationality5",
        headerName: "6",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationalityPercentage5",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "nationalityNb5",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationality6",
        headerName: "7",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationalityPercentage6",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "nationalityNb6",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationality7",
        headerName: "8",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationalityPercentage7",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "nationalityNb7",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationality8",
        headerName: "9",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationalityPercentage8",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "nationalityNb8",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nationality9",
        headerName: "10",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data.value : "",
    },
    {
        field: "nationalityPercentage9",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "nationalityNb9",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nbDifferentNationality",
        headerName: "Nationalité différentes",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
];

function Nationality(props) {

    const {
        rowsState,
        rowCountState,
        selectionModel,
        setSelectionModel,
        defaultPageSize,
        setDefaultPageSize,
        setDefaultPageNo,
        setRowsState,
        defaultPageNo,
        inputs
    } = props

    const [nationalities, setNationalities] = useState();
    const [loadingState, setLoadingState] = useState(false);

    const refresh = () => {
        setLoadingState(true);
        SecretService.getNationalityStat(inputs.startDate, inputs.endDate)
            .then((results) => {
                let datas = results.data;
                datas.forEach((data) => {
                    let inconnu = false;
                    for (let i = 0; i < data.nationalityStats.length; i++) {
                        Object.defineProperty(data, "nationality" + i, {
                            value: (!inconnu && !data.nationalityStats[i].type ? "(non renseigné)" : data.nationalityStats[i].type),
                        });
                        if (!inconnu && !data.nationalityStats[i].type) {
                            inconnu = true;
                        }
                        Object.defineProperty(data, "nationalityNb" + i, {
                            value: data.nationalityStats[i].number,
                        });
                        Object.defineProperty(data, "nationalityPercentage" + i, {
                            value: data.nationalityStats[i].percentage,
                        });
                    }
                })
                setNationalities(results.data);
                setLoadingState(false)
            })
            .catch(err => console.log(err));
    }
    useEffect(() => {
        refresh();
    }, [inputs])

    return (
        <>
            {/*
            <h3
                style={{
                    position: "relative",
                    fontFamily: "backToSchool",
                    fontSize: "45px",
                    color: "#bb0000",
                    marginTop: "20px",
                }}
            >
                Nationalites
            </h3>
            */}
            {nationalities != null ?
                (
                    <>
                        <div>
                            <ExportDataGridToExcel
                                headers={[
                                    { header: "Id", hideable: true },
                                    { headerName: "Site" },
                                    { headerName: "Nationalité 1" },
                                    { headerName: "1 Quantité %" },
                                    { headerName: "1 Quantité" },
                                    { headerName: "Nationalité 2" },
                                    { headerName: "2 Quantité %" },
                                    { headerName: "2 Quantité" },
                                    { headerName: "Nationalité 3" },
                                    { headerName: "3 Quantité %" },
                                    { headerName: "3 Quantité" },
                                    { headerName: "Nationalité 4" },
                                    { headerName: "4 Quantité %" },
                                    { headerName: "4 Quantité" },
                                    { headerName: "Nationalité 5" },
                                    { headerName: "5 Quantité %" },
                                    { headerName: "5 Quantité" },
                                    { headerName: "Nationalité 6" },
                                    { headerName: "6 Quantité %" },
                                    { headerName: "6 Quantité" },
                                    { headerName: "Nationalité 7" },
                                    { headerName: "7 Quantité %" },
                                    { headerName: "7 Quantité" },
                                    { headerName: "Nationalité 8" },
                                    { headerName: "8 Quantité %" },
                                    { headerName: "8 Quantité" },
                                    { headerName: "Nationalité 9" },
                                    { headerName: "9 Quantité %" },
                                    { headerName: "9 Quantité" },
                                    { headerName: "Nationalité 10" },
                                    { headerName: "10 Quantité %" },
                                    { headerName: "10 Quantité" },
                                    { headerName: "Nationalité différentes" }
                                ]}
                                data={nationalities.map(row => ({
                                    "Id": "",
                                    "Site": row?.siteName,
                                    "Nationalité 1": row?.nationality0,
                                    "1 Quantité %": row?.nationalityPercentage0,
                                    "1 Quantité": row?.nationalityNb0,
                                    "Nationalité 2": row?.nationality1,
                                    "2 Quantité %": row?.nationalityPercentage1,
                                    "2 Quantité": row?.nationalityNb1,
                                    "Nationalité 3": row?.nationality2,
                                    "3 Quantité %": row?.nationalityPercentage2,
                                    "3 Quantité": row?.nationalityNb2,
                                    "Nationalité 4": row?.nationality3,
                                    "4 Quantité %": row?.nationalityPercentage3,
                                    "4 Quantité": row?.nationalityNb3,
                                    "Nationalité 5": row?.nationality4,
                                    "5 Quantité %": row?.nationalityPercentage4,
                                    "5 Quantité": row?.nationalityNb4,
                                    "Nationalité 6": row?.nationality5,
                                    "6 Quantité %": row?.nationalityPercentage5,
                                    "6 Quantité": row?.nationalityNb5,
                                    "Nationalité 7": row?.nationality6,
                                    "7 Quantité %": row?.nationalityPercentage6,
                                    "7 Quantité": row?.nationalityNb6,
                                    "Nationalité 8": row?.nationality7,
                                    "8 Quantité %": row?.nationalityPercentage7,
                                    "8 Quantité": row?.nationalityNb7,
                                    "Nationalité 9": row?.nationality8,
                                    "9 Quantité %": row?.nationalityPercentage8,
                                    "9 Quantité": row?.nationalityNb8,
                                    "Nationalité 10": row?.nationality9,
                                    "10 Quantité %": row?.nationalityPercentage9,
                                    "10 Quantité": row?.nationalityNb9,
                                    "Nationalité différentes": row?.nbDifferentNationality
                                }))}
                                filename={"statistiques_nationalite"}
                            />
                        </div>
                        <div className="EventDataGrid">
                            <DataGrid
                                rowsPerPageOptions={[rowsState.pageSize]}
                                sx={{ backgroundColor: "#fff" }}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                        },
                                    },
                                }}
                                columns={nationalityColumns}
                                rows={nationalities}
                                loading={loadingState}
                                rowCount={rowCountState}
                                showCellRightBorder
                                pagination
                                {...rowsState}
                                paginationMode="server"
                                checkboxSelection={false}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    const selectedIDs = new Set(newSelectionModel);
                                    const selectedRows = nationalities.filter((row) =>
                                        selectedIDs.has(row.id)
                                    );
                                    setSelectionModel(newSelectionModel);
                                }}
                                onPageChange={(page) => {
                                    setDefaultPageNo(page);
                                    setRowsState((prev) => ({ ...prev, page }));
                                    refresh();
                                }}
                                onPageSizeChange={(pageSize) => {
                                    setDefaultPageSize(pageSize);
                                    setRowsState((prev) => ({ ...prev, pageSize }));
                                    refresh();
                                }}
                            />
                        </div>
                    </>
                ) :
                (
                    <>
                      <Box textAlign="center" mt={20}>
                            <LoadingButton loading={true} variant="outlined" disabled>
                                Loading
                            </LoadingButton>
                        </Box>
                    </>
                )
            }
        </>
    )
}
export default Nationality;