import { TextField } from "@material-ui/core";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";
import Client from "./Grid/Client";
import MaritalStatus from "./Grid/MaritalStatus";
import Nationality from "./Grid/Nationality";
import Service from "./Grid/Service";
import Voucher from "./Grid/Voucher";
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

const serviceColumnsBase = [
  {
    field: "id",
    header: "Id",
    hideable: true,
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "siteName",
    headerName: "Site",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    cellClassName: "tableLeftColumn",
  },
  {
    field: "nbMeal",
    headerName: "Repas",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
]

function GroupedStat() {

  const [value, setValue] = useState("0");

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  var defaultStart = yyyy + "-01-01";
  var defaultEnd = yyyy + "-12-31";

  const [inputs, setInputs] = useState({
    startDate: defaultStart,
    endDate: defaultEnd,
  });

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(20);

  const [focus, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const handleChangeStart = (newValue) => {
    if (newValue == null) {
      setInputs({
        dateEvent: null,
        dateLimitInscription: null,
      });
    } else {
      const yyyy = newValue.getFullYear();
      let mm = newValue.getMonth() + 1;
      let dd = newValue.getDate();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      var datestr = yyyy + "-" + mm + "-" + dd;
      setInputs({
        startDate: datestr,
        endDate: inputs.endDate,
      });
    }
  };

  const handleChangeEnd = (newValue) => {
    if (newValue == null) {
      setInputs({
        dateEvent: null,
        dateLimitInscription: null,
      });
    } else {
      const yyyy = newValue.getFullYear();
      let mm = newValue.getMonth() + 1;
      let dd = newValue.getDate();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      var datestr = yyyy + "-" + mm + "-" + dd;
      setInputs({
        startDate: inputs.startDate,
        endDate: datestr,
      });
    }
  };

  const resetInput = () => {
    setInputs({
      startDate: null,
      endDate: null,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        textAlign: "center",
        padding: "2%",
      }}
    >
      <div className="event-form">
        <h3
          style={{
            position: "relative",
            fontFamily: "backToSchool",
            fontSize: "65px",
            color: "#bb0000",
          }}
        >
          Statistiques regroupées
        </h3>
        <div
          className="row"
          style={{ alignItems: "center" }}
        >

          <div className="col-5">
            <h6 style={{ marginBottom: "0px" }}>Date de début :</h6>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                value={inputs.startDate}
                inputFormat="dd/MM/yyyy"
                onChange={handleChangeStart}
                onFocus={onFocus}
                onBlur={onBlur}
                renderInput={(params) => <TextField {...params} />}
                type={hasValue || focus ? "date" : "text"}
              />
            </LocalizationProvider>
          </div>
          <div className="col-5">
            <div style={{ marginLeft: "10%" }}>
              <h6 style={{ marginBottom: "0px" }}>Date de fin :</h6>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  value={inputs.endDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={handleChangeEnd}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  renderInput={(params) => <TextField {...params} />}
                  type={hasValue || focus ? "date" : "text"}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="col-2">
            <div className="clearResearch">
              <Tooltip title="Vider la recherche" placement="top">
                <button
                  onClick={(e) => {
                    resetInput();
                  }}
                >
                  <FilterAltOffIcon />
                </button>
              </Tooltip>
            </div>
          </div>

          <TabContext value={value}>
            <TabList value={value} onChange={(e, tabIndex) => setValue(tabIndex)} aria-label="Statistiques regroupées">
              <Tab label="Services" value="0" />
              <Tab label="Clients" value="1" />
              <Tab label="Bons" value="2" />
              <Tab label="Nationalités" value="3" />
              <Tab label="Etat civil" value="4" />
            </TabList>

            <TabPanel value="0">
              <Service
                rowsState={rowsState}
                rowCountState={rowCountState}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                defaultPageSize={defaultPageSize}
                setDefaultPageSize={setDefaultPageSize}
                setDefaultPageNo={setDefaultPageNo}
                setRowsState={setRowsState}
                defaultPageNo={defaultPageNo}
                inputs={inputs}
              />
            </TabPanel>
            <TabPanel value="1">
              <Client
                rowsState={rowsState}
                rowCountState={rowCountState}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                defaultPageSize={defaultPageSize}
                setDefaultPageSize={setDefaultPageSize}
                setDefaultPageNo={setDefaultPageNo}
                setRowsState={setRowsState}
                defaultPageNo={defaultPageNo}
                inputs={inputs}
              />
            </TabPanel>
            <TabPanel value="2">
              <Voucher
                rowsState={rowsState}
                rowCountState={rowCountState}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                defaultPageSize={defaultPageSize}
                setDefaultPageSize={setDefaultPageSize}
                setDefaultPageNo={setDefaultPageNo}
                setRowsState={setRowsState}
                defaultPageNo={defaultPageNo}
                inputs={inputs}
              />
            </TabPanel>
            <TabPanel value="3">
              <Nationality
                rowsState={rowsState}
                rowCountState={rowCountState}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                defaultPageSize={defaultPageSize}
                setDefaultPageSize={setDefaultPageSize}
                setDefaultPageNo={setDefaultPageNo}
                setRowsState={setRowsState}
                defaultPageNo={defaultPageNo}
                inputs={inputs}
              />
            </TabPanel>
            <TabPanel value="4">
              <MaritalStatus
                rowsState={rowsState}
                rowCountState={rowCountState}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                defaultPageSize={defaultPageSize}
                setDefaultPageSize={setDefaultPageSize}
                setDefaultPageNo={setDefaultPageNo}
                setRowsState={setRowsState}
                defaultPageNo={defaultPageNo}
                inputs={inputs}
              />
            </TabPanel>
          </TabContext>
          
        </div>
      </div>
    </div>
  );
}
export default GroupedStat;